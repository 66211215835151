import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { User } from '../models/user';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    constructor(private httpClient: HttpClient) {}

    setUser(user: User): Observable<any> {
        return new Observable((observer) => {
            const xhr = new XMLHttpRequest();

            xhr.open('POST', `${environment.apiUrl}Users/register`, true);

            xhr.onload = () => {
                if (xhr.status === 200) {
                    observer.next(xhr.response);
                    observer.complete();
                } else {
                    observer.error(xhr.statusText);
                }
            };

            let formData = new FormData();
            formData.append('FirstName', user.firstName);
            formData.append('LastName', user.lastName);
            formData.append('Password', user.password);

            if (user.photo) {
                formData.append('Photo', user.photo);
            }

            formData.append('Key', user.key);

            xhr.send(formData);
        });
    }

    getSelf(): Observable<User> {
        return this.httpClient.get<User>(`${environment.apiUrl}Users/self`);
    }

    updateUser(user: User): Observable<any> {
        let formData = new FormData();

        formData.append('Email', user.email);
        formData.append('FirstName', user.firstName);
        formData.append('LastName', user.lastName);
        formData.append('PhotoId', user.photoId);

        if (user.photo) {
            formData.append('Photo', user.photo);
        }

        return this.httpClient.put<string>(
            `${environment.apiUrl}Users/self`,
            formData
        );
    }

    updateUserLanguage(language: number): Observable<string> {
        return this.httpClient.put<string>(
            `${environment.apiUrl}Users/self/language`,
            {language}
        );
    }
}
